import React from 'react'
import Highlight, {defaultProps} from 'prism-react-renderer'
import dracula from 'prism-react-renderer/themes/vsDark';

export const CodeBlock = ({children, className, live, render}) => {
    const language = className.replace(/language-/, '')
    console.dir(children)
    return (
        <Highlight {...defaultProps} code={children} language={language} theme={dracula}>
          {({className, style, tokens, getLineProps, getTokenProps}) => (
            <pre className={className} style={{...style, padding: '10px 20px 10px 10px', marginBottom: '10px', overflow: 'scroll'}}>
              {tokens
                .filter( (line) => line.length > 1 )
                .map((line, i) => (
                <div key={i} {...getLineProps({line, key: i})}>
                  <span style={{marginRight: '10px', textAlign: 'right', width: '2rem', display: 'inline-block', opacity: '0.5'}}> {i} </span>
                  {line.map((token, key) => (
                    <span key={key} {...getTokenProps({token, key})} />
                  ))}
                </div>
              ))}
            </pre>
          )}
        </Highlight>
      )
}

export default CodeBlock
