import React from 'react'
import { Link } from 'gatsby'

const slug = (str) => {
    return str.toLowerCase().split().join('-')
}

export const TableOfContent = (props) => {
    console.dir(props)
    const headings = props.headings;
    return (
        <div className="toc">
        <div className='leaders'>
            <div>
            <span className='h3 title'>Table of content</span>
            </div>
        </div>
        <ul>
            {headings
            .map(heading => (
                <li key={heading.value} style={{marginLeft: heading.depth+'rem'}}>
                <Link to={'#' + slug(heading.value)}>{heading.value}</Link>
                </li>
            ))}
        </ul>
        </div>
    );
}

export default TableOfContent
