import React from 'react'
import { Link } from "gatsby"

import { MDXRenderer } from 'gatsby-plugin-mdx'
import { MDXProvider } from "@mdx-js/react"

import TableOfContent from './BlogPage/tableOfContent'
import CodeBlock from './BlogPage/codeBlock'

import "../assets/css/publication_style.css"
import "katex/dist/katex.min.css"

const components = {
    TOC: props => <TableOfContent {...props} />,
    code: CodeBlock,
    inlineCode: props => <span className="inline-code" {...props} />
};

const PublicationPage = props => {

return (
    <div>
    <h2 className="major" style={{width: '98%'}}> 
        {props.data.frontmatter.title} <br></br>
        <span className="in-title"> by <Link to={'/'}  style={{color: 'peru', fontWeight: 'bold'}}>{props.site.author}</Link> </span> 
    </h2>
    <ul class="details">
        <span> <i class="fa fa-calendar" style={{'margin-right': '10px', color: 'peru'}}></i> <span style={{'background-color': '#404040', 'border-radius': '5px', 'padding-left': '5px', 'padding-right': '5px', fontFamily: 'monospace'}}>{props.data.frontmatter.date}</span></span>

        <span className="float-right">
            <ul style={{'list-style': 'none', margin: '0px', padding: '0px'}}>
            <li style={{'display': 'inline-block', 'margin-right': '10px'}}><i class="fa fa-tags" style={{color: 'peru'}}></i></li>
            {props.data.frontmatter.tags.map((tag, index) => {
                return (
                // <li><a href="#"> {tag} </a></li>
                <li style={{'display': 'inline-block', 'background-color': '	#404040', 'border-radius': '5px', 'margin-left': '0.1rem', 'margin-right': '0.2rem', 'padding-right': '5px', 'padding-left': '5px', fontFamily: 'monospace'}}>{tag}</li>
                )
            })}
            </ul>
        </span>
    </ul>

    <div id="content">
        <MDXProvider components={components}>
            <MDXRenderer frontmatter={props.data.frontmatter} headings={props.data.headings}>{props.data.body}</MDXRenderer>
        </MDXProvider>
    </div>
    </div>
)
}

// PublicationPage.propTypes = {
    
// }

export default PublicationPage
