import React, {useState, useRef, useEffect} from 'react';
import { graphql, Link } from 'gatsby';
import { useLocation } from '@reach/router';

import SEO from '../components/SEO'

import BlogPage from '../components/BlogPage'

import '../assets/css/custom.css'

const Template = ({ data }) => {
  const [clock, setClock] = useState(false);
  const clockRef = useRef(clock);
  clockRef.current = clock;

  useEffect(() => {
    setTimeout(() => {
      setClock(true)
    }, 150) 
  }, [])

  const location = useLocation()
  var linkState = {}
  if (location.key != 'initial') {
      linkState = {section: 'blog'}
  }

  const post = data.mdx;
  const site = data.site.siteMetadata;

  return (
    <div class="body is-article-visible">
    <SEO title={post.frontmatter.title} description={post.excerpt} image={post.frontmatter.socialMedia} cardType="summary_large_image"/>
    <div id="wrapper">
    <div id="main" style={{ display: 'flex' }}> 
    <article
          id="blog"
          className={clock ? 'active timeout' : ''} 
          style={{ 'width': '90vw' }} 
    >
      {/* <Link to={'/'} state={linkState}><div
        className="close"
      ></div></Link> */}

      <BlogPage data={post} site={site} />

    </article>
    </div>
    <div id="bg"></div>
    </div>
    </div>
  );
};

export const query = graphql`
  query($slug: String!) {
    mdx(fields: { slug: { eq: $slug } }) {
      frontmatter {
        title
        rawDate: date
        date(formatString: "DD MMMM YYYY")
        tags
      }
      headings {
        value
        depth
      }
      excerpt(truncate: true)
      body
    },
    site {
      siteMetadata {
        siteUrl
        author
      }
    }
  }
`;

export default Template;
